/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage et liste des evenements.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import { GetEvents } from '../components/graphql/getEvents';
import EventCard from './../components/events/event-card';
import Layout from './../components/template/layout';

const Events = () => {

	const events = GetEvents();
	const isEvent = events.length > 0 ? true : false;
	const currentTime = new Date().getTime();
	console.log(events);
	return (
		<Layout 
			title="Evénements"
			lang="fr"
			description="Liste des événements"
			social={
				{
					type: 'website',
				}
			}
		>
			<h1>Evénements</h1>
			{isEvent &&
				<div className="event-page page">
					{events.map((event, key) => (
						new Date(event.node.frontmatter.date).getTime() > currentTime &&
						<EventCard
							key={key}
							image={event.node.frontmatter.featuredImage.childImageSharp.fixed}	
							title={event.node.frontmatter.title}
							date={event.node.frontmatter.date}
							body={event.node.html}
							categorie={event.node.frontmatter.categorie}
							id={event.node.frontmatter.id}
						/> 
					))}
				</div>
			}
			{!isEvent &&
			<p>Aucun événement à afficher</p>
			}
		</Layout>
	);
};

export default Events;